import React, { ReactElement } from 'react';

import { Navigate } from 'react-router-dom';

import { loadSettingsData } from 'frontend/routing/loaders';

import { BaseSettings } from 'frontend/settings/base';
import { BillingOverview } from 'frontend/settings/billing-overview';
import { MergeTeams } from 'frontend/settings/merge-teams';
import { Subscription } from 'frontend/settings/subscription';
import { TeamFeatures } from 'frontend/settings/team-features';
import { TeamIntegrations } from 'frontend/settings/team-integrations';
import { TeamOverview } from 'frontend/settings/team-overview';
import { TeamThemes } from 'frontend/settings/team-themes';
import { UserFeatures } from 'frontend/settings/user-features';
import { UserOverview } from 'frontend/settings/user-overview';
import { UserPreferences } from 'frontend/settings/user-preferences';

import { AppStore } from 'reports/store';

import { selectors as authSelectors } from 'reports/modules/auth';

const settingsRoutes = (store: AppStore) => ({
    path: 'settings',
    element: <BaseSettings />,
    id: 'settings',
    loader: async ({ request }) => await loadSettingsData(store, new URL(request.url).searchParams),
    children: [
        {
            path: 'user',
            element: <UserOverview />,
        },
        {
            path: 'user/changepassword',
            element: <UserOverview changePassword={true} />,
        },
        {
            path: 'user/preferences',
            element: <UserPreferences />,
        },
        {
            path: 'team',
            element: <TeamOverview />,
        },
        {
            path: 'team/integrations',
            element: <TeamIntegrations />,
        },
        {
            path: 'team/themes',
            element: <TeamThemes />,
        },
        {
            path: 'team/billing',
            element: <BillingOverview />,
        },
        {
            path: 'hs-admin/subscription',
            element: requireHSAdmin(store, <Subscription />),
        },
        {
            path: 'hs-admin/merge-teams',
            element: requireHSAdmin(store, <MergeTeams />),
        },
        {
            path: 'hs-admin/team-features',
            element: requireHSAdmin(store, <TeamFeatures />),
        },
        {
            path: 'hs-admin/user-features',
            element: requireHSAdmin(store, <UserFeatures />),
        },
    ],
});

const isHSAdmin = (store: AppStore) => {
    const state = store.getState();
    const user = authSelectors.getUser(state)!;
    return user != null && user.is_admin;
};

const requireHSAdmin = (store: AppStore, Component: ReactElement) =>
    isHSAdmin(store) ? Component : <Navigate to="/403" replace />;

export { settingsRoutes };
