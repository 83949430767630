import React from 'react';

import { Outlet } from 'react-router-dom';
import styled from '@xstyled/styled-components';

import * as DS from '@aurorasolar/ds';

import { useToasters } from 'reports/modules/Toaster';
import { useNavigationItems } from 'frontend/routing/use-navigation-items';
import { HS_BRANDMARK, HS_BRANDMARK_SIZE, HS_LOGO, HS_LOGO_SIZE } from 'frontend/layout/branding';

const StyledPage = styled(DS.Page)<{ shortenActionBar: boolean }>`
    nav[data-component='navigation-bar'] {
        background-color: uiActionBar.defaultBgColor;
        grid-template-columns: auto auto auto;
        z-index: 1;
    }

    nav[data-component='navigation-bar'] button {
        color: uiButtonContextualNavigation.dropdownTextColor;
    }

    div[data-component='contextual-navigation'] {
        margin-top: -45px;
        z-index: 2;
    }

    // Since we set margin-top to -45px, we set max-height to none to allow for the contextual navigation to grow to the
    // bottom of the page.
    div[data-subcomponent='contextual-navigation-body'] {
        max-height: none;
    }

    p {
        font-size: 14px;
    }

    a {
        font-weight: normal;
    }
`;

const Page = React.memo(({ children }: React.PropsWithChildren<{}>) => {
    const { contextualNavigation, pageNavigation } = useNavigationItems();
    useToasters();

    // Shorten the ActionBar if there are groups in the contextualNavigation.
    const shortenActionBar = !!contextualNavigation.groups.length;
    return (
        <StyledPage
            brandmarkIcon={HS_BRANDMARK}
            brandmarkIconProps={HS_BRANDMARK_SIZE}
            logoIcon={HS_LOGO}
            logoIconProps={HS_LOGO_SIZE}
            pageNavigation={pageNavigation}
            shortenActionBar={shortenActionBar}
            variant="tertiary"
        >
            {children}
            <DS.ContextualNavigation contextualNavigation={contextualNavigation} />
            <Outlet />
        </StyledPage>
    );
});

export { Page };
