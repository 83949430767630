import * as React from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import { AppStore } from 'reports/store';

import { Page } from 'frontend/layout/page-layout';
import { settingsRoutes } from 'frontend/routing/settings-routes';

const Router = ({ basename = '/app', store }: { basename: string; store: AppStore }) => {
    // Trying this style of defining routes. settingsRoutes was starting to bloat.
    // This structure is definitely not set in stone and is highly subject to change.
    // projectRoutes and librariesRoutes will likely move into their own file much
    // like settingsRoutes.
    const projectsRoutes = {
        path: 'projects',
        element: <div>Projects</div>,
    };

    const librariesRoutes = {
        path: 'libraries',
        element: <div>Libraries</div>,
    };

    // This is a placeholder and will likely live somewhere else in the near future.
    const errorRoutes = {
        path: '403',
        element: <div>403</div>,
    };

    // This welcome route is likely temporary. It is in place so that users don't see
    // a 404 when they navigate from classic -> foxfire.
    const welcomeRoute = {
        path: '/welcome',
        element: <div>Welcome</div>,
    };

    const router = createBrowserRouter(
        [
            {
                path: '/',
                element: <Page />,
                children: [errorRoutes, welcomeRoute, projectsRoutes, librariesRoutes, settingsRoutes(store)],
            },
        ],
        {
            basename,
        },
    );
    return <RouterProvider router={router} />;
};

export { Router };
