import * as React from 'react';

import { Intent, Tag } from '@blueprintjs/core';

import { Section2 } from 'reports/components/core/containers';
import { TeamFeaturesForm } from 'reports/modules/settings/admin/components/TeamFeaturesForm';

import { Team } from 'reports/models/team';
import { User } from 'reports/models/user';

interface Props {
    team: Team;
    user: User;
}

const TeamFeatures = ({ team, user }: Props) => (
    <Section2 title="Feature Flags" contextEl={<Tag intent={Intent.DANGER}>HelioScope admin only</Tag>}>
        <TeamFeaturesForm team={team} user={user} />
    </Section2>
);

export { TeamFeatures };
