const routes = {
    'app.signup': '/signup',
    'app.not-activated': '/not-activated',
    'app.set-password': '/set-password/:token',
    'app.reset-password': '/reset-password/:token',
    'app.forgot-password': '/forgot-password',
    'app.auth-tokens': '/auth-tokens',
    'app.projects': '/projects',
    'app.projects.project': '/projects/:projectId',
    'app.projects.project.sharing': '/projects/:projectId/sharing',
    'app.projects.project.simulation': '/projects/:projectId/simulation',
    'app.projects.project.designer': '/projects/:projectId/designer/:designId/*subpath',
    'app.projects.project.overview': '/projects/:projectId/overview',
    'app.projects.project.financial-configurations': '/projects/:projectId/financial-configurations',
    'app.projects.project.financial-configurations.financial-configuration':
        '/projects/:projectId/financial-configurations/:finConfigId',
    'app.projects.project.report.configure': '/projects/:projectId/report/configure',
    'app.projects.project.report.view': '/projects/:projectId/report/view',
    'app.projects.project.conditions': '/projects/:projectId/conditions',
    'app.projects.project.conditions.condition': '/projects/:projectId/conditions/:scenarioId',
    'app.projects.project.conditions.condition.edit': '/projects/:projectId/conditions/:scenarioId/edit',
    'app.welcome': '/welcome',
    'app.profiles': '/profiles',
    'app.modules': '/modules',
    'app.weather_sources': '/weather_sources',
    'app.wires': '/wires',
    'app.reports': '/reports?projectId',
    'app.reports.preview': '/reports/:slug?reportId/preview',
    'app.reports.edit': '/reports/:slug?reportId/edit',
    'app.profiles.profile': '/profiles/:profileId',
    'app.profiles.preview': '/profiles/:profileId/preview',
    'app.profiles.edit': '/profiles/:profileId/edit',
    'app.financial-templates': '/financial-models',
    'app.financial-templates.financial-template.preview': '/financial-models/:finTemplateId/preview',
    'app.financial-templates.financial-template.edit': '/financial-models/:finTemplateId/edit',
    'app.utility-rates': '/utility-rates',
    'app.utility-rates.utility-rate': '/utility-rates/:rateId',
    'app.incentives': '/incentives',
    'app.incentives.incentive': '/incentives/:incentiveId',
    'app.power-devices': '/components',
    'app.power-devices.power-device.characterizations.preview':
        '/components/:powerDeviceId/characterizations/:characterizationId/preview',
    'app.settings.team.overview': '/settings/team',
    'app.settings.team.manage': '/settings/team/manage',
    'app.settings.team.integrations': '/settings/team/integrations',
    'app.settings.team.themes': '/settings/team/themes',
    'app.settings.team.billing': '/settings/team/billing',
    'app.settings.team.features': '/settings/team/features',
    'app.settings.user': '/settings/user',
    'app.settings.user.changepassword': '/settings/user/changepassword',
    'app.settings.user.preferences': '/settings/user/preferences',
    'app.settings.user.features': '/settings/user/features',
    'app.settings.hs-admin': '/settings/hs-admin',
    'app.settings.hs-admin.subscription': '/settings/hs-admin/subscription',
    'app.settings.merge-teams': '/settings/hs-admin/merge-teams',
    'app.settings.hs-admin.team-features': '/settings/hs-admin/team-features',
    'app.settings.hs-admin.user-features': '/settings/hs-admin/user-features',
};

export { routes };
