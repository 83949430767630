import { useDispatch } from 'react-redux';
import React from 'react';

import { Intent } from '@blueprintjs/core';

import { api as SSOProviderApi, SSOProvider } from 'reports/models/sso_provider';

import { Toaster } from 'reports/modules/Toaster';
import { IntegrationOption } from './ExternalIntegrationsList';

import SSOCredentialsDialog, { clientSecretPlaceholder } from './SSOCredentialsDialog';

import { User } from 'reports/models/user';

interface SSOCredentialsContainerProps {
    handleClose: () => void;
    isOpen: boolean;
    options: IntegrationOption;
    user: User;
}

const SSOCredentialsContainer = ({ handleClose, isOpen, options, user }: SSOCredentialsContainerProps) => {
    const dispatch = useDispatch();
    const [ssoProvider, setSSOProvider] = React.useState<SSOProvider>(new SSOProvider({}));
    const [loadingSSOProvider, setLoadingSSOProvider] = React.useState<boolean>(false);

    React.useEffect(() => {
        const fetchData = async () => {
            setLoadingSSOProvider(true);
            try {
                const ssoProvider = await dispatch(SSOProviderApi.get({ teamId: user.team_id }));
                setSSOProvider(ssoProvider);
            } catch {
                setSSOProvider(new SSOProvider({}));
            }
            setLoadingSSOProvider(false);
        };

        fetchData();
    }, []);

    const onSubmit = async (formData: SSOProvider) => {
        if (formData.client_secret === clientSecretPlaceholder) {
            delete formData.client_secret;
        }

        const ssoProvider = await dispatch(
            formData.sso_provider_id ? SSOProviderApi.save(formData) : SSOProviderApi.create(formData),
        );
        setSSOProvider(ssoProvider);
        Toaster.show({
            intent: Intent.SUCCESS,
            message: `Successfully saved SSO Provider`,
            timeout: 5000,
        });
    };

    return (
        <SSOCredentialsDialog
            handleClose={handleClose}
            isOpen={isOpen}
            onSubmit={onSubmit}
            options={options}
            ssoProvider={ssoProvider}
            loadingSSOProvider={loadingSSOProvider}
        />
    );
};

export default SSOCredentialsContainer;
