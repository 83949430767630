import React from 'react';

import { Link as Router5Link } from 'react-router5';
import { useLocation, Link as RouterLink, URLSearchParamsInit } from 'react-router-dom';

import { createRoutePath } from 'reports/routing/common';

type Props = React.PropsWithChildren<{
    className?: string;
    routeName: string;
    routeParams?: any;
    searchParams?: URLSearchParamsInit | { [x: string]: any };
}>;

const Link = ({ children, className, routeName, routeParams, searchParams = {} }: Props) => {
    try {
        const location = useLocation();
        const to = createRoutePath(routeName, routeParams, location.search, searchParams);
        return <RouterLink to={to}>{children}</RouterLink>;
    } catch (error) {
        return (
            <Router5Link
                className={className}
                routeName={routeName}
                routeParams={{ ...routeParams, ...(searchParams as object) }}
            >
                {children}
            </Router5Link>
        );
    }
};

export { Link };
