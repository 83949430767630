import { selectors as authSelectors } from 'reports/modules/auth';
import { api as teamAPI, Team } from 'reports/models/team';
import { api as userAPI, selectors as userSelectors, User } from 'reports/models/user';

import { Subscription } from 'reports/models/subscription';
import { actions as subscriptionActions } from 'reports/modules/subscription';

import { AppStore } from 'reports/store';

const getUser = async (store: AppStore, searchParams: URLSearchParams): Promise<User> => {
    const state = store.getState();
    const email = searchParams.get('email');

    if (email) {
        const user: User | undefined = userSelectors.byEmail(state, email);
        return user ? user : await store.dispatch(userAPI.get({ email }));
    }
    return authSelectors.getUser(state)!;
};

const getTeam = async (store: AppStore, team_id: number): Promise<Team> => {
    return await store.dispatch(teamAPI.get({ team_id }));
};

const getSubscription = async (store: AppStore, subscription?: Subscription) =>
    subscription ? await store.dispatch(subscriptionActions.loadFullSubscription(subscription)) : undefined;

const loadSettingsData = async (
    store: AppStore,
    searchParams: URLSearchParams,
): Promise<{ subscription?: Subscription; team: Team; user: User }> => {
    const user = await getUser(store, searchParams);
    const team = await getTeam(store, user.team_id);

    const subscription = await getSubscription(store, team.latest_subscription || user.latest_subscription);

    return { subscription, team, user };
};

export { loadSettingsData };
