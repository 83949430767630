// This file is intended to be a proof of concept. If you need to make changes
// in here, please ensure that you are working with the MBU epic owner.
import { useCallback, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import * as DS from '@aurorasolar/ds';

import { selectors as authSelectors } from 'reports/modules/auth';
import { IAppState } from 'reports/store';

const useNavigationItems = () => {
    const user = useSelector((state: IAppState) => authSelectors.getUser(state)!);
    const navigate = useNavigate();
    const location = useLocation();
    const currentPathName = location.pathname;
    const isActive = useCallback(
        (pathName: '/projects' | '/libraries' | '/settings') => currentPathName?.includes(pathName),
        [currentPathName],
    );

    const isActiveContextual = useCallback(
        (
            pathName:
                | '/profiles'
                | '/modules'
                | '/overview'
                | '/designer'
                | '/conditions'
                | '/simulations'
                | '/financials'
                | '/sharing'
                | '/reports'
                | '/user-overview'
                | '/settings/hs-admin/subscription'
                | '/settings/hs-admin/merge-teams'
                | '/settings/hs-admin/team-features'
                | '/settings/hs-admin/user-features'
                | '/settings/team'
                | '/settings/team/billing'
                | '/settings/team/integrations'
                | '/settings/team/themes'
                | '/settings/user'
                | '/settings/user/changepassword'
                | '/settings/user/preferences'
                | '/team-management',
        ) => currentPathName?.endsWith(pathName),
        [currentPathName],
    );

    const contextualNavigationGroups = () => {
        if (isActive('/projects')) {
            return [
                {
                    items: [
                        {
                            action: () => navigate('/overview'),
                            active: isActiveContextual('/overview'),
                            activeIcon: DS.IconDashboardFill,
                            children: 'Overview',
                            icon: DS.IconDashboard,
                        },
                        {
                            action: () => navigate('/designer'),
                            active: isActiveContextual('/designer'),
                            activeIcon: DS.IconDesignMode,
                            children: 'Designer',
                            icon: DS.IconDesignMode,
                        },
                        {
                            action: () => navigate('/conditions'),
                            active: isActiveContextual('/conditions'),
                            activeIcon: DS.IconShade,
                            children: 'Conditions',
                            icon: DS.IconShade,
                        },
                        {
                            action: () => navigate('/simulations'),
                            active: isActiveContextual('/simulations'),
                            activeIcon: DS.IconPlugFill,
                            children: 'Simulations',
                            icon: DS.IconPlug,
                        },
                        {
                            action: () => navigate('/financials'),
                            active: isActiveContextual('/financials'),
                            activeIcon: DS.IconDollarCircleFill,
                            children: 'Financials',
                            icon: DS.IconDollarCircle,
                        },
                        {
                            action: () => navigate('/sharing'),
                            active: isActiveContextual('/sharing'),
                            activeIcon: DS.IconUserFill,
                            children: 'Sharing',
                            icon: DS.IconUser,
                        },
                        {
                            action: () => navigate('/reports'),
                            active: isActiveContextual('/reports'),
                            activeIcon: DS.IconPaperLinesFill,
                            children: 'Reports & proposals',
                            icon: DS.IconPaperLines,
                        },
                    ],
                },
            ];
        }

        if (isActive('/libraries')) {
            return [
                {
                    title: 'Libraries',
                    items: [
                        {
                            action: () => navigate('/profiles'),
                            active: isActiveContextual('/profiles'),
                            children: 'Profiles',
                        },
                        {
                            action: () => navigate('/modules'),
                            active: isActiveContextual('/modules'),
                            children: 'Modules',
                        },
                        {
                            action: () => console.log('clicked'),
                            children: 'Inverters & Optimizers',
                        },
                        {
                            action: () => console.log('clicked'),
                            children: 'Weather Sources',
                        },
                        {
                            action: () => console.log('clicked'),
                            children: 'Wires',
                        },
                    ],
                },
                {
                    title: 'Reports and proposals',
                    items: [
                        {
                            action: () => console.log('clicked'),
                            children: 'Report templates',
                        },
                    ],
                },
                {
                    title: 'Quoting',
                    items: [
                        {
                            action: () => console.log('clicked'),
                            children: 'Financial models',
                        },
                        {
                            action: () => console.log('clicked'),
                            children: 'Incentives',
                        },
                        {
                            action: () => console.log('clicked'),
                            children: 'Utility rates',
                        },
                    ],
                },
            ];
        }

        if (isActive('/settings')) {
            const settingsRoutes = [
                {
                    title: 'Account',
                    items: [
                        {
                            action: () => navigate('/settings/user'),
                            active:
                                isActiveContextual('/settings/user') ||
                                isActiveContextual('/settings/user/changepassword'),
                            children: 'Account Overview',
                        },
                        {
                            action: () => navigate('/settings/user/preferences'),
                            active: isActiveContextual('/settings/user/preferences'),
                            children: 'Preferences',
                        },
                    ],
                },
                {
                    title: 'Team',
                    items: [
                        {
                            action: () => navigate('/settings/team'),
                            active: isActiveContextual('/settings/team'),
                            children: 'Team Overview',
                        },
                        {
                            action: () => navigate('/team-management'),
                            active: isActiveContextual('/team-management'),
                            children: 'Manage Team',
                        },
                        {
                            action: () => navigate('/settings/team/integrations'),
                            active: isActiveContextual('/settings/team/integrations'),
                            children: 'Integrations',
                        },

                        {
                            action: () => navigate('/settings/team/themes'),
                            active: isActiveContextual('/settings/team/themes'),
                            children: 'Themes',
                        },
                        {
                            action: () => navigate('/settings/team/billing'),
                            active: isActiveContextual('/settings/team/billing'),
                            children: 'Billing & Usage',
                        },
                    ],
                },
            ];

            const adminRoutes = [
                {
                    title: 'HS Admin',
                    items: [
                        {
                            action: () => navigate('/settings/hs-admin/subscription'),
                            active: isActiveContextual('/settings/hs-admin/subscription'),
                            children: 'Subscription',
                        },
                        {
                            action: () => navigate('/settings/hs-admin/merge-teams'),
                            active: isActiveContextual('/settings/hs-admin/merge-teams'),
                            children: 'Merge Teams',
                        },

                        {
                            action: () => navigate('/settings/hs-admin/team-features'),
                            active: isActiveContextual('/settings/hs-admin/team-features'),
                            children: 'Team Features',
                        },
                        {
                            action: () => navigate('/settings/hs-admin/user-features'),
                            active: isActiveContextual('/settings/hs-admin/user-features'),
                            children: 'User Features',
                        },
                    ],
                },
            ];

            return user && user.is_admin ? [...settingsRoutes, ...adminRoutes] : settingsRoutes;
        }

        return [];
    };

    const pageNavigation = useMemo<DS.INavigationItemGroup[]>(
        () => [
            {
                items: [
                    {
                        action: () => navigate('/projects'),
                        active: isActive('/projects'),
                        activeIcon: DS.IconProjectFill,
                        children: 'Projects',
                        icon: DS.IconProject,
                    },
                    {
                        action: () => navigate('/libraries'),
                        active: isActive('/libraries'),
                        activeIcon: DS.IconDatabaseFill,
                        children: 'Libraries',
                        icon: DS.IconDatabase,
                    },
                    {
                        action: () => navigate('/settings/team'),
                        active: isActive('/settings'),
                        activeIcon: DS.IconGearFill,
                        children: 'Settings',
                        icon: DS.IconGear,
                    },
                ],
            },
        ],
        [currentPathName, isActive],
    );

    const contextualNavigation = useMemo(
        () => ({
            groups: contextualNavigationGroups(),
        }),
        [currentPathName],
    );

    return { contextualNavigation, pageNavigation };
};

export { useNavigationItems };
